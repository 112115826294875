<template>
  <div>
    <div class="quit">
      <a @click.stop="gotoHome">
        <v-icon left>mdi-arrow-left</v-icon>
      </a>
    </div>
    <div class="container-typed">
      <h1 class="typed">HTNB</h1>
    </div>
    <div id="render"></div>
  </div>
</template>

<script>
import * as THREE from "three";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { SSRPass } from "three/examples/jsm/postprocessing/SSRPass.js";
// import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
// import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader.js";
import { ReflectorForSSRPass } from "three/examples/jsm/objects/ReflectorForSSRPass.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { RoomEnvironment } from "three/examples/jsm/environments/RoomEnvironment.js";

export default {
  name: "HTNB",

  mounted() {
    let camera, scene, renderer, controls;

    let mixer;
    let modelReady = false;

    let selects = [];
    let groundReflector;
    let ssrPass;
    let composer;

    const minDistance = 30;
    const maxDistance = 100; // 100

    const clock = new THREE.Clock();

    init();
    animate();

    function init() {
      const container = document.getElementById("render");

      camera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        1,
        4000
      );

      // scene

      scene = new THREE.Scene();
      scene.background = new THREE.Color(0x000000);
      // scene.fog = new THREE.Fog(0x443333, 1, 200);

      // const hemiLight = new THREE.HemisphereLight(0xf6eedc, 0x111122);
      // scene.add(hemiLight);

      //   const dirLight = new THREE.DirectionalLight(0xffffff);
      //   dirLight.position.set(30, 25.0, 30);
      //   dirLight.target.position.set(8.25, 0, 7.75);
      //   dirLight.castShadow = true;
      //   dirLight.shadow.camera.top = 50;
      //   dirLight.shadow.camera.bottom = -25;
      //   dirLight.shadow.camera.left = -25;
      //   dirLight.shadow.camera.right = 25;
      //   dirLight.shadow.camera.near = 0.1;
      //   dirLight.shadow.camera.far = 100;
      //   dirLight.shadow.mapSize.set(1024, 1024);
      //   scene.add(dirLight);

      const spotLight = new THREE.SpotLight(0xffffff);
      spotLight.angle = Math.PI / 4;
      spotLight.penumbra = 0.5;
      spotLight.castShadow = true;
      spotLight.position.set(30, 45.0, 30);
      scene.add(spotLight);

      //   const cameraHelper = new THREE.CameraHelper(spotLight.camera);
      //   scene.add(cameraHelper);

      scene.add(camera);

      const mesh = new THREE.Mesh(
        new THREE.PlaneGeometry(1e4, 1e4),
        new THREE.MeshPhongMaterial({
          color: 0x999999,
          specular: 0x101010,
        })
      );
      mesh.rotation.x = -Math.PI / 2;
      mesh.receiveShadow = false;
      scene.add(mesh);

      const planeReflector = new THREE.PlaneGeometry(1, 1);
      groundReflector = new ReflectorForSSRPass(planeReflector, {
        clipBias: 0.0003,
        textureWidth: window.innerWidth,
        textureHeight: window.innerHeight,
        color: 0x888888,
        useDepthTexture: true,
      });
      groundReflector.material.depthWrite = false;
      groundReflector.rotation.x = -Math.PI / 2;
      groundReflector.visible = false;
      scene.add(groundReflector);

      // model

      const onProgress = function (xhr) {
        if (xhr.lengthComputable) {
          const percentComplete = (xhr.loaded / xhr.total) * 100;
          console.log(Math.round(percentComplete, 2) + "% downloaded");
        }
      };

      const compressor = new DRACOLoader();
      compressor.setDecoderPath("/draco/");
      compressor.setDecoderConfig({ type: "js" });

      const loader = new GLTFLoader();
      loader.setPath("models/");
      loader.setDRACOLoader(compressor);

      loader.load(
        "revd.gltf",
        function (gltf) {
          const model = gltf.scene;
          model.scale.set(1e2, 1e2, 1e2);
          model.position.y = 1;

          mixer = new THREE.AnimationMixer(model);

          model.traverse(function (object) {
            if (object.isMesh) {
              object.castShadow = true;
              object.receiveShadow = true;
              if (object.name.includes("Compound")) {
                selects.push(object);
              }
            }
          });
          scene.add(model);
          modelReady = true;
        },
        onProgress
      );

      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.outputEncoding = THREE.sRGBEncoding;

      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap;

      const environment = new RoomEnvironment();
      const pmremGenerator = new THREE.PMREMGenerator(renderer);
      scene.environment = pmremGenerator.fromScene(environment).texture;
      environment.dispose();

      composer = new EffectComposer(renderer);
      ssrPass = new SSRPass({
        renderer,
        scene,
        camera,
        width: innerWidth,
        height: innerHeight,
        groundReflector: groundReflector,
        selects: selects,
      });
      ssrPass.opacity = 1;
      groundReflector.opacity = 1;

      const bloomPass = new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        1.5,
        0.4,
        0.85
      );
      // bloomPass.threshold = 0;
      // bloomPass.strength = 0.5;
      // bloomPass.radius = 0;

      // composer.addPass(ssrPass);
      // composer.addPass(new ShaderPass(GammaCorrectionShader));
      const renderScene = new RenderPass(scene, camera);
      composer.addPass(renderScene);
      composer.addPass(bloomPass);

      container.appendChild(renderer.domElement);
      container.appendChild(renderer.domElement);

      controls = new OrbitControls(camera, renderer.domElement);
      controls.minDistance = minDistance;
      controls.maxDistance = maxDistance;
      controls.minPolarAngle = (10.0 * Math.PI) / 180.0;
      controls.maxPolarAngle = (80.0 * Math.PI) / 180.0;

      camera.position.set(-25, -25, 50);
      controls.target.set(8.25, 0, 7.75);
      controls.update();

      window.addEventListener("resize", onWindowResize);
    }

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    }

    function animate() {
      requestAnimationFrame(animate);
      render();
    }

    function render() {
      controls.update();
      if (modelReady) mixer.update(clock.getDelta());
      // composer.render();
      renderer.render(scene, camera);
    }
  },

  methods: {
    gotoHome() {
      this.$router.push("/hello");
    },
  },
};
</script>

<style scoped>
.container-typed {
  display: inline-block;
  font-family: "arial";
  font-size: 24px;
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translate(-50%, 0%);
}

.typed {
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid;
  width: 0;
  animation: typing 1.5s steps(30, end) forwards, blinking 1s infinite;
  color: #fe9800ff;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blinking {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #fe9800ff;
  }
  100% {
    border-color: transparent;
  }
}

.quit {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
